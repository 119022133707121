import * as Twilio from 'twilio-video'
import axios from 'axios'

export default class twilioVideoChat {
    constructor (userToken,activeProfile,roomId,localVideo,remoteVideo,sentry)
    {
        this.activeRoom = null;
        this.loading = true;
        this.localVideoObj = localVideo;
        this.remoteVideoObj = remoteVideo;
        this.userToken = userToken;
        this.activeProfile = activeProfile;
        this.roomId = roomId;
        this.roomName = null;
        this.localTrack = false;
        this.remoteTrack = false;
        this.sentry = sentry;
        this.localTracks = null;
        this.mediaErrors = [
            'NotAllowedError',
            'NotFoundError',
            'NotReadableError',
            'OverconstrainedError',
            'TypeError'
        ]; 
    }    
    handleMediaError(error) {
        console.log('Failed to acquire media:', error.name, error.message);
    }
    // Turns local mic on
    localMicOn() {
        this.activeRoom.localParticipant.audioTracks.forEach(async publication => {
            await publication.track.enable();
        });
    } 
    // Turns local mic off
    localMicOff() {
        this.activeRoom.localParticipant.audioTracks.forEach(async publication => {
            await publication.track.disable();
        });
    }      
    // Turns local camera on
    localCameraOn(){ 
        this.activeRoom.localParticipant.videoTracks.forEach(async publication => {
            await publication.track.enable();
        });    
        let videoElement = this.localVideoObj;
        videoElement.style.display = "block";
        //document.getElementById("local_avatar").style.display = "none";    
    }
    // Turns local camera off
    localCameraOff(){
        this.activeRoom.localParticipant.videoTracks.forEach(async publication => {
            await publication.track.disable();
        });
        let videoElement = this.localVideoObj;
        videoElement.style.display = "none";
        //document.getElementById("local_avatar").style.display = "block";
    }
    // Handles change in media device choice in Video Settings
    updateVideoDevice(deviceId) {
        const localThis = this;
        if (localThis.activeRoom) {
            try {
                const localParticipant = localThis.activeRoom.localParticipant;
                if (deviceId !== null) {
                    Twilio.createLocalVideoTrack({
                        "deviceId": deviceId
                    })
                    .then((localVideoTrack) => {
                        console.log(JSON.stringify("new local video: " + localVideoTrack));
                        const track = Array.from(localThis.activeRoom.localParticipant.videoTracks.values())[0].track;
                        localParticipant.unpublishTrack(track);
                        track.stop();
                        localThis.unloadLocal(track);
                        localParticipant.publishTrack(localVideoTrack);
                        localThis.loadLocal(localVideoTrack);
                    })
                    .catch(localThis.handleMediaError);
                }
            } catch(error){
                localThis.sentry.captureException(error);
                console.log(error);
            }
        }
    }
    // Handles change in media device choice in Video Settings
    updateAudioDevice(deviceId) {
        const localThis = this;
        if (localThis.activeRoom) {
            try {
                //! this is throwing a bug for some users where it's null.
                /* possible that this gets triggered before call is fully established if settings is enabled from menu prior to activeRoom getting set. */
                const localParticipant = localThis.activeRoom.localParticipant;
                if (deviceId !== null) {
                    Twilio.createLocalAudioTrack({
                        "deviceId": deviceId
                    })
                    .then((localAudioTrack) => {
                        console.log(JSON.stringify("new local mic: " + localAudioTrack));
                        const track = Array.from(localThis.activeRoom.localParticipant.audioTracks.values())[0].track;
                        
                        localParticipant.unpublishTrack(track);
                        track.stop();
                        track.detach(); 
                        localParticipant.publishTrack(localAudioTrack);
                        localAudioTrack.attach();
                    })
                    .catch(localThis.handleMediaError);
                }
            } catch(error){
                localThis.sentry.captureException(error);
                console.log(error);
            }
        }
    }    
    // Handles audio / video toggle off
    handleTrackDisabled(track){
        console.log("disable a remote track");    
        const localThis = this;
        if(track.kind == "audio") {
            document.getElementById("remote_mic_status").src = "/img/btns/video_chat/mic_indicator_off.svg";            
        } else if (track.kind == "video") {
            let videoElement = document.getElementById('remote_video_cam');
            videoElement.style.display = "none";
            document.getElementById("remote_avatar").style.display = "block";
        }    
    }
    // Handles audio / video toggle on
    handleTrackEnabled(track) {
        if(track.kind == "audio") {
            document.getElementById("remote_mic_status").src = "/img/btns/video_chat/mic_indicator.svg";
        } else if (track.kind == "video") {
            let videoElement = document.getElementById('remote_video_cam');

            document.getElementById("remote_avatar").style.display = "none";
            videoElement.style.display = "block";
        }
    }
    // Loads local media to DOM
    loadLocal(track){
        const localThis = this;
        console.log("Attaching local video");
        if(!localThis.localTrack) {
            const localVideoTrack = track;
            let videoElement = localThis.localVideoObj.appendChild(localVideoTrack.attach());
            videoElement.width = 160;
            videoElement.style.transform = 'scale(-1, 1)';
            localThis.localVideoObj.style.display = "block";
            localThis.localTrack = true;
        }  
    }
    // Removes local media from DOM
    unloadLocal(track){
        const localThis = this;
        const localVideoTrack = track;
        localVideoTrack.detach(); 
        localThis.localVideoObj.innerHTML = "";   
        localThis.localTrack = false;    
    }
    // Adds Remote media to DOM
    loadRemoteTrack(track){
        const localThis = this;   
        console.log("Attaching remote video");      
        document.getElementById("invite_guest").style.display = "none";
        document.getElementById("remote_avatar").style.display = "none";
        let videoElement = localThis.remoteVideoObj.appendChild(track.attach());
        videoElement.width = 160;
        videoElement.style.transform = 'scale(-1, 1)';     
        localThis.remoteVideoObj.style.display = "block";   
        localThis.localTrack = true;   
    }
    // Clears Remote user from DOM
    unloadRemoteTrack(track){
        const localThis = this; 
        track.detach(); 
        localThis.remoteVideoObj.innerHTML = "";
    }
    // 
    loadParticipant(participant){
        const localThis = this;   
        //console.log('Participant connected');
        participant.on('trackSubscribed', track => localThis.loadRemoteTrack(track));
        participant.on('trackUnsubscribed', track => localThis.unloadRemoteTrack(track));
        // Handles cam / mic disables and enables
        participant.on('trackEnabled', track => localThis.handleTrackEnabled(track));
        participant.on('trackDisabled', track => localThis.handleTrackDisabled(track));
        participant.tracks.forEach(publication => {
          if (publication.track) {
            localThis.loadRemoteTrack(publication.track);
          } 
        });
    }
    // Full disconnect
    unloadParticipant(participant){
        const localThis = this; 
        //console.log('Participant disconnected');
        localThis.remoteVideoObj.innerHTML = "";
        // TODO this should probably disconnect more things.
        // TODO remove event binding?
        participant.tracks.forEach(publication => {
            if (publication.track) {
              //localThis.loadRemoteTrack(publication.track);
              localThis.unloadRemoteTrack(publication.track)
            } 
        });    
    }  
    // Ends call
    endChat(){
        const localThis = this;
        if (localThis.activeRoom) {
            const track = Array.from(localThis.activeRoom.localParticipant.videoTracks.values())[0].track;
            localThis.activeRoom.disconnect();
            this.localTracks.forEach(track => track.stop());
            localThis.unloadLocal(track);
            localThis.activeRoom = null;
        }
    }    
    // Gets Twilio Token from FB Function
    async getAccessToken() {
        let token_url = process.env.VUE_APP_FIREBASE_FUNCTIONS+'twilio?token='+this.userToken;
        let payload = {
            activeProfile: this.activeProfile
        }
        return await axios.post(token_url,payload); 
    }    
    // Twilio setup / Starts call
    async createChat() {
        const localThis = this;
        this.localTracks = await Twilio.createLocalTracks().catch(localThis.handleMediaError);
        localThis.getAccessToken()
        .then( (data) => {
            const token = data.data.token;
            localThis.roomName = 'playspaces-room-' + localThis.roomId;
            let connectOptions = {
                name: localThis.roomName,
                tracks: localThis.localTracks,
                //logLevel: 'debug',
                preferredVideoCodecs: ['H264'],
                audio: true,
                RecordParticipantsOnConnect: false,
                video: {
                    width: 160,
                }
            };
            // before a user enters a new room,
            // disconnect the user from they joined already
            localThis.endChat();
            
            // remove any remote track when joining a new room
            Twilio.connect(token , connectOptions)
            .then(function(room) {
                console.log('Connected to Room :', room.name);
                // Connects Remote Video and Audio Track to Remote Video Feed
                localThis.activeRoom = room;
                let localVideoTracks = Array.from(localThis.activeRoom.localParticipant.videoTracks.values())[0].track;
                console.log("local video settings: " + JSON.stringify(localVideoTracks));
                localThis.loadLocal(localVideoTracks);
                room.participants.forEach(participant => localThis.loadParticipant(participant));
                room.on('participantConnected', participant => localThis.loadParticipant(participant));        
                room.on('participantDisconnected', participant => localThis.unloadParticipant(participant));
                room.on('participantReconnecting', participant => localThis.loadParticipant(participant));    
                room.on('participantReconnected', participant => localThis.unloadParticipant(participant));
                room.on('reconnecting', () => {localThis.loadLocal(localVideoTracks);});
                room.on('reconnected', () => {localThis.loadLocal(localVideoTracks);});                 
                room.once('disconnected', error => room.participants.forEach(participant => localThis.unloadParticipant(participant)));  

            })
            .catch(error => {
                if (this.mediaErrors.includes(error.name)) {
                    // Handle media error here.
                    //localThis.handleMediaError(error);
                    window.alert("Video Connection Error: " + error + ". Please verify that you've enabled video.");
                } else {
                    localThis.sentry.captureException(error);
                    console.log(JSON.stringify(error));
                }                
            });
        })
        .catch(error => {
            localThis.sentry.captureException(error);
            console.log(JSON.stringify(error));
        })
    }
}

export { twilioVideoChat };