<template>
  <div>
    <b-toast id="my-toast" variant="primary" auto-hide-delay="5000" solid @click="hideToast()">
      {{toastMsg}}
    </b-toast>
  </div>
</template>

<script>
export default {
    name: "toast", 
    props: {
        message: String
    },
    watch: {
        'message': {
            handler (newVal) {
                if (newVal === true) { 
                    this.toastMsg = this.message
                }
            },
            immediate: true 
        },
    },
    data () {
        return {
            toastMsg: this.message
        }
    } 
}
</script>

<style lang="scss" >
@import '@/assets/scss/variables';
.b-toast .toast {
    background: $ps-tall-card;
    font-weight: $ps-heavier-weight;
    color: $ps-black;
    font-size: $btn-font-size;
    line-height: $btn-font-lh;     
    //filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    padding: 8px 12px;
    border: none;
}
.toast-header {
    display: none;
}

</style>