<template>
    <div id="video-settings">
        <Smallmodal
        :modalShow="showModal"
        @closeSmallModal="closeModal()"
        >
        <div id="video-settings-content">
            <h2>{{$t('videochat.videosettings')}}</h2>
            <div v-if="error" class="error-msg">{{error}}</div>
            <br/><br/>
            <div id="camera-preview-container">
                <div id="camera-preview"></div>
            </div>
            <form>
                <div v-if="cameras.length > 0">
                    <div class="d-inline-block top-align">
                        <img src="/img/btns/video_chat/settings_camera.svg" width="30" height="30" :alt="this.$t('videochat.camera')" :title="this.$t('videochat.camera')"/>
                    </div>
                    <div class="video-option d-inline-block top-align">
                        <select 
                        id="camera" 
                        name="camera" 
                        class="form-control video-select"
                        v-model.trim="videosettings.camera"
                        tabindex="1"
                        >
                            <option v-for="camera in cameras" v-bind:value="camera.value" v-bind:key="camera.id">
                                {{ camera.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <br/>
                <div v-if="mics.length > 0">
                    <div class="d-inline-block top-align">
                        <img src="/img/btns/video_chat/settings_mic.svg" width="30" height="30" :alt="this.$t('videochat.mic')" :title="this.$t('videochat.mic')"/>
                    </div>
                    <div class="video-option d-inline-block top-align">
                        <select 
                        id="mic" 
                        name="mic" 
                        class="form-control video-select"
                        v-model.trim="videosettings.mic"
                        tabindex="2"
                        >
                            <option class="form-control" v-for="mic in mics" v-bind:value="mic.value" v-bind:key="mic.id">
                                {{ mic.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <br/>
                <div v-if="speakers.length > 0">
                    <div class="d-inline-block top-align">
                        <img src="/img/btns/video_chat/settings_speaker.svg" width="30" height="30" :alt="this.$t('videochat.speaker')" :title="this.$t('videochat.speaker')"/>
                    </div>
                    <div class="video-option d-inline-block top-align">
                        <select 
                        id="speaker" 
                        name="speaker" 
                        class="form-control video-select"
                        v-model.trim="videosettings.speaker"
                        tabindex="3"
                        >
                            <option class="form-control" v-for="speaker in speakers" v-bind:value="speaker.value" v-bind:key="speaker.id">
                                {{ speaker.text }}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
            <br/><br/>
            <b-button pill variant="primary" @click="updateSettings()">
                <div class="confirm-icon ps-icons d-inline-block"></div>
                {{$t('global.confirm')}}
            </b-button>

        </div>
        </Smallmodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'
export default {
    name: "Videosettings", 
    components: {
        Smallmodal
    },
    props:{
        showModal: Boolean,
    },
    computed: {
        ...mapState(['userData']), 
    },
    watch: {
        'showModal':{
            handler (newVal){
                if(this.showVideoSettings === true){
                    this.gotDevices();
                }
            }
        }        
    },    
    data: function() {
        return {
            error: null,
            cameras: [],
            mics: [],
            speakers: [],
            videosettings: {
                camera: null,
                mic: null,
                speaker:  null                
            }
        }   
    },
    methods: {
        closeModal() {
            this.$emit('closeVideoSettings');
        },
        updateSettings(){
            let settings = {
                camera: this.videosettings.camera,
                mic: this.videosettings.mic,
                speaker:  this.videosettings.speaker 
            }
            //console.log(JSON.stringify(settings));
            this.$emit('updateVideoSettings',settings);
        },
        async gotDevices() {
            try {
                let count = 1;
                let mediaDevices = await this.getInputDevices();
                let videoDevices = [];
                let audioOutDevices = [];
                let audioInDevices = [];
                mediaDevices.forEach(mediaDevice => {
                    if (mediaDevice.kind === 'videoinput') {
                        const label = mediaDevice.label || `Camera ${count  }`;
                        let camera = {
                            text: label,
                            value: mediaDevice.deviceId
                        }
                        videoDevices.push(camera);
                    }
                    if (mediaDevice.kind === 'audioinput') {
                        const label = mediaDevice.label || `Mic ${count  }`;
                        let mic = {
                            text: label,
                            value: mediaDevice.deviceId
                        }
                        audioInDevices.push(mic);
                    } 
                    /*
                    if (mediaDevice.kind === 'audiooutput') {
                        const label = mediaDevice.label || `Speaker ${count  }`;
                        let speaker = {
                            text: label,
                            value: mediaDevice.deviceId
                        }
                        audioOutDevices.push(speaker);
                    } */                     
                })
                this.cameras = videoDevices;
                this.speakers = audioOutDevices;
                this.mics = audioInDevices;
                if(this.cameras.length > 0){
                    this.videosettings.camera = this.cameras[0].value;  
                } else {
                    //this.error = this.$t('errors.nocamera');
                }
                if(this.mics.length > 0){
                    this.videosettings.mic = this.mics[0].value; 
                } else {
                    //this.error = this.$t('errors.nomic');
                }
                if(this.speakers.length > 0){
                    this.videosettings.speaker = this.speakers[0].value; 
                } else {
                    //this.error = this.$t('errors.nospeaker');
                }
            } catch(error){
                console.log(error);
            }
        },
        async getInputDevices() {
            try {
                let browserName = this.$browserDetect.meta.name;
                const devices = await navigator.mediaDevices.enumerateDevices() || await navigator.getUserMedia || await navigator.webkitGetUserMedia || await navigator.mozGetUserMedia;
                //console.log(JSON.stringify(devices));
                return devices;//.filter(device => device.kind === `${kind}input`);
            } catch (error){
                console.log(error);
            }
        }         
    },
    mounted(){
        // Load devices from browser
        
        this.gotDevices();
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

#video-settings {
    #video-settings-content {
        padding: 30px 0;
        h2 {
            margin: 0 0 0 0;
        }
        .video-option {
            width: 200px;
            margin: 0 0 0 20px;
        }
        .video-select {
            -webkit-appearance:none;    
            cursor: pointer;        
        }

    }
}

</style>