<template>
<div>  
    <!-- TODO clean this up so modals load from 1 component -->
    <Inactive
    :showInactive="show.inactive"
    :inactiveStep2="show.inactiveStep2"
    @disconnectSession="endCall()"
    @closeInactive="closeInactive()"
    />    
    <PlayerWaiting
    :showModal="show.waiting"
    :playerName="waitingPlayerName"
    @startVideo="openPlayspace()"
    @closePlayerleft="closePlayerWaiting()"
    @endCurrentStartNew="clearRoomStartCall()"
    @snoozeStart="waitingSnooze()"
    />    
    <PlayerLeft
    :showModal="show.playerleft"
    :playerName="leavingPlayerName"
    @disconnectSession="endCall()"
    @closePlayerleft="closePlayerleft()"
    />
    <VideoSettings
    :showModal="show.videoSettings" 
    @updateVideoSettings="updateVideo($event)"
    @closeVideoSettings="closeVideoSettings()"
    />    
    <ExitModal
    :modalShow="confirmModal" 
    :error="exitError"
    @cancelModalAction="closeConfirmModal()" 
    @leaveWithoutSaving="justExit()"
    @confirmSaveExit="saveExit()"
    />   
    <!-- end small modals -->    
    <AgeGate
    :showAgeGate="show.agegate" 
    :nextAction="nextAction"  
    @correctPIN="pinAccepted($event)" 
    @closeAgeGate="closeAgeGate()"
    />    
    <!-- Large Modals -->
    <Ceateinvite
    :showCreateInvite="show.createInvite"
    @closeCreateInvite="closeCreateInviteFromVideoChat()"       
    />     
    <Activities
    :activityModalShow="activityModal"
    :focusSaves="show.saves"
    @closeActivityModal="closeActivityModal()"
    @changeActivity="triggerActivity($event)"
    @loadSavedActivity="triggerSavedActivity($event)"
    />  
    <!-- End Large Modals -->
    <div id="video_chat">
        <div id="local_video">
            <div class="video-shadow">
            <div class="video-crop">
                <div id="local_avatar" @click="startCall()" class="avatar-sqr" v-if="show.localAvatar == true">
                    <img :src="localProfile.profilePhoto" width="80" height="80"/>
                </div>
                <!--div id="start_video" @click="startCall()">{{$t('videochat.startvideo')}}</div-->
                <div id="local_video_cam"></div>
            </div>
            </div>
            <div id="local_name" class="chat_names" v-show="show.localname"><div class="truncate">{{localProfile.name}}</div> <img class="mic-indicator" id="local_mic_status" src="/img/btns/video_chat/mic_indicator.svg" width="14" height="14"/></div>
        </div>
        <div id="video_controls">
            <ul>
                <li>
                    <a id="activities_btn" @click="triggerActivtiesMenu()">
                        <img src="/img/btns/video_chat/star.png" width="30" height="30" class="star-btn video-ctl-btn" :alt="$t('videochat.activities')" :title="$t('videochat.activities')"/>
                        {{$t('videochat.activities')}}
                    </a>
                </li>
                <li>
                    <a id="camera_btn" @click="toggleCamera()" class="disabled">
                        <img src="/img/btns/video_chat/camera_disabled.svg" width="30" height="30" class="video-ctl-btn" :alt="$t('videochat.camera')" :title="$t('videochat.camera')"/>
                        {{this.cameraText}}
                    </a>
                </li>
                <li>
                    <a id="mic_btn" @click="toggleMic()" class="disabled">
                        <img src="/img/btns/video_chat/mic_disabled.svg" width="30" height="30" class="video-ctl-btn" :alt="$t('videochat.mic')" :title="$t('videochat.mic')"/>
                        {{this.micText}}
                    </a>
                </li>
                <li>
                    <a id="settings_btn" @click="openVideoSettings()" class="disabled">
                        <img src="/img/btns/video_chat/settings_disabled.svg" width="30" height="30" class="video-ctl-btn" :alt="$t('videochat.settings')" :title="$t('videochat.settings')"/>
                        {{$t('videochat.settings')}}
                    </a>
                </li>
                <li>
                    <a id="exit_btn" @click="leaveRoom()">
                        <img src="/img/btns/video_chat/exit.svg" width="30" height="30" class="video-ctl-btn" :alt="$t('videochat.leave')" :title="$t('videochat.leave')"/>
                        {{$t('videochat.leave')}}
                    </a>
                </li>
            </ul>
        </div>
        <div id="remote_video">
            <div class="video-shadow">
            <div class="video-crop">
                <div id="remote_avatar" class="avatar-sqr">
                    <img :src="remoteProfile.profilePhoto" width="80" height="80"/>
                </div>
                <div id="invite_guest" @click="openAgeGate()">
                    <img src="/img/btns/video_chat/invite.svg" width="80" height="80" :title="$t('videochat.inviteguest')" :alt="$t('videochat.inviteguest')"/>
                </div>
                <div id="remote_video_cam"></div>
            </div>
            </div>
            <div id="remote_name" class="chat_names" v-show="show.remotename"><div class="truncate">{{remoteProfile.name}}</div> <img class="mic-indicator" id="remote_mic_status" src="/img/btns/video_chat/mic_indicator.svg" width="14" height="14"/></div>
        </div>
    </div>
</div>    
</template>

<script>
//TODO refactor the DOM manipulations to properly use VUEJS reactivitiy.
import { mapState } from 'vuex'
import { twilioVideoChat } from '@/common/videochat'
import Activities from '@/components/activities.vue'
import ExitModal from '@/components/modals/exitandsave.vue'
import Ceateinvite from '@/components/createinvite.vue'
import AgeGate from '@/components/modals/agegate.vue'
import VideoSettings from '@/components/modals/videosettings.vue' 
import PlayerLeft from '@/components/modals/playerleft.vue'
import PlayerWaiting from '@/components/modals/playerwaiting.vue'
import Inactive from '@/components/modals/inactive.vue'
export default {
    name: "videochat",  
    components: {
        Activities,
        ExitModal,
        Ceateinvite,
        AgeGate,
        VideoSettings,
        PlayerLeft,
        PlayerWaiting,
        Inactive
    },
    props: {
        closeLargeModals: Boolean
    },
    watch: {              
        'closeLargeModals': {
            handler (newVal) {
                if (newVal) { 
                    if(this.closeLargeModals === true) {
                        this.closeActivityModal();
                        this.closeCreateInviteFromVideoChat();
                        this.$emit('modalsClosed'); 
                    }
                }
            },
            immediate: true 
        }, 
        'activeProfile': {
            handler (newVal) {
                if (newVal) { 
                    this.localProfile = this.activeProfile;
                }
            },
            immediate: true 
        },                                                 
    },  
    computed: {
        ...mapState(['activeProfile']),         
        ...mapState(['currentRoom']), 
        ...mapState(['currentActivity']),    
    },
    data() {
        return {
            inactivityTimerRunning: false,
            timeOut: null,
            inactivityTimer: null,
            channel: null,    
            widthVideo: 200,            
            hasMounted: false,
            keepcallAlive: false,
            activityModal: false,
            confirmModal: false,
            exitError: null,
            feedbackData: {
                show: true,
                playerName: null,
                sessionType: "solo",
                sessionRole: "host"
            },            
            nextAction: null,                
            show: {
                waiting: false,
                videoSettings: false,
                createInvite: false,
                remotename: false,
                playerleft: false,
                localname: true,
                agegate: false,
                localAvatar: true,
                remoteAvatar: false,
                inactive: false,
                inactiveStep2: true,
                saves: false
            },
            cameraText: this.$t('videochat.camera'),
            micText: this.$t('videochat.mic'),
            localTrack: false,
            remoteTrack: false,
            cameraStatus: 'disabled',
            micStatus: 'disabled',
            settingsStatus: 'disabled',
            // TODO refactor
            waitingPlayerName: null,
            leavingPlayerName: "",
            leavingPlayerPhoto: "",
            // ? is this necessary?
            playerWaitingDimissCount: 0,
            snoozetimeOut: 0,
            snoozetimeCount: null,          
            waitingProfile: {
                userProfileId: null,
                name: this.$t('videochat.guestname'),
                profilePhoto: "/img/avatars/unset_avatar.svg",
                type: null
            },
            leavingProfile: {
                userProfileId: null,
                name: this.$t('videochat.guestname'),
                profilePhoto: "/img/avatars/unset_avatar.svg",
                type: null
            },      

            localProfile: {
                userProfileId: null,
                name: this.$t('videochat.hostname'),
                profilePhoto: "/img/avatars/unset_avatar.svg",
                type: null
            },
            remoteProfile: {
                userProfileId: null,
                name: this.$t('videochat.guestname'),
                profilePhoto: "/img/avatars/unset_avatar.svg",
                type: null
            }     
        }
    }, 
    methods: {
        inactivityTime() {
            this.timeOut = 9 * 60 * 1000;
            this.inactivityTimerRunning = true;
            this.resetInactivityTimer();
            window.addEventListener("mousemove", () => {this.resetInactivityTimer()}, true); 
            window.addEventListener("mousedown", () => {this.resetInactivityTimer()}, true); 
            window.addEventListener("touchstart", () => {this.resetInactivityTimer()}, true); 
            window.addEventListener("click", () => {this.resetInactivityTimer()}, true);
            window.addEventListener("keydown", () => {this.resetInactivityTimer()}, true); 
            window.addEventListener("scroll", () => {this.resetInactivityTimer()}, true);  
        }, 
        alertInactivityTimer() {
            //this.$store.commit('setInactive', true);
            this.show.inactive = true;
        },      
        resetInactivityTimer() {
            if(this.inactivityTimerRunning) {
                this.show.inactive = false;
                //this.$store.commit('setInactive', false);
                clearInterval(this.inactivityTimer);
                this.inactivityTimer = setInterval(() => {this.alertInactivityTimer()}, this.timeOut);
            } else {
                clearInterval(this.inactivityTimer);
            }
        },
        clearInactivityTime() {
            this.inactivityTimerRunning = false;
            clearInterval(this.inactivityTimer);
            this.inactivityTimer = null;
        },        
        activateVideoControls(){
            this.cameraStatus = 'on';
            this.micStatus = 'on';
            this.settingsStatus = 'enabled';            
            //document.getElementById("start_video").style.display = "none";
            document.getElementById("camera_btn").firstElementChild.src = "/img/btns/video_chat/camera_on.svg";
            document.getElementById("camera_btn").classList.remove("disabled");
            document.getElementById("mic_btn").firstElementChild.src = "/img/btns/video_chat/mic_on.svg";
            document.getElementById("mic_btn").classList.remove("disabled");
            document.getElementById("settings_btn").firstElementChild.src = "/img/btns/video_chat/settings_on.svg";
            document.getElementById("settings_btn").classList.remove("disabled");   
            this.cameraText = this.$t('videochat.camera') + " " + this.$t('global.on');
            this.micText = this.$t('videochat.mic') + " " + this.$t('global.on');
        },
        deActivateVideoControls(){
            //document.getElementById("start_video").style.display = "block";
            document.getElementById("mic_btn").classList.remove("off");
            document.getElementById("camera_btn").classList.remove("off");
            document.getElementById("camera_btn").firstElementChild.src = "/img/btns/video_chat/camera_disabled.svg";
            document.getElementById("camera_btn").classList.add("disabled");
            document.getElementById("mic_btn").firstElementChild.src = "/img/btns/video_chat/mic_disabled.svg";
            document.getElementById("mic_btn").classList.add("disabled");
            document.getElementById("settings_btn").firstElementChild.src = "/img/btns/video_chat/settings_disabled.svg";
            document.getElementById("settings_btn").classList.add("disabled");   
            document.getElementById("invite_guest").style.display = "block";
            this.cameraText = this.$t('videochat.camera') + " " + this.$t('global.off');
            this.micText = this.$t('videochat.mic') + " " + this.$t('global.off');            
        },
        async endCall() {
            this.deActivateVideoControls();
            this.clearInactivityTime();  
            this.show.localAvatar = true;
            this.show.playerleft = false;
            await this.twilioVideoChat.endChat(); 
            if(this.activeProfile.host) {
                //this.$store.dispatch('removePlayers');  
                this.$store.dispatch('closeRoom');                
            } else {
                //this will punt guests out of the room.
                this.justExit(); 
            }            
        },                   
        async startCall() {
            this.inactivityTime();  
            this.$emit('callStarted');
            
            document.getElementById("local_mic_status").style.display = "block";
            this.show.localAvatar = false;
            this.show.localname = true;
            this.show.remotename = true;      

            await this.twilioVideoChat.createChat()
            .then(() => {this.activateVideoControls();});
            
            if(this.activeProfile.host) {
                this.lobby.trigger('client-host-room-open', {message: 'room-open'});
                await this.$store.dispatch('openRoom');                
                this.$store.dispatch('sessionLog', {
                    activity: this.currentActivity  ||  "unset",
                    action: "start call",
                    content: null,
                    players: this.players
                }); 
                this.tracking({
                    type: 'event',
                    category: 'activity:nav',
                    action: 'startvideo'
                });                    
            } 
        },
        triggerActivtiesMenu(){
            if(this.activeProfile.host) {
                this.channel.trigger('client-host-openactivity', {message: this.$t('toasts.client-host-openactivity')});
            } else {
                this.channel.trigger('client-guest-openactivity', {message: this.$t('toasts.client-guest-openactivity')});              
            }
            this.openActivitiesModal(); 
        },
        openActivitiesModal() {
            if(!this.activityModal) {
                this.activityModal = true;
                this.closeCreateInviteFromVideoChat();
                this.$emit('closeOtherLargeModals');   
                this.tracking({
                    type: 'event',
                    category: 'activity:nav',
                    action: 'activities'
                });
            } else {
                this.activityModal = false;
            }
        },
        triggerActivity(activity) {
            this.loadActivity(activity);
            this.channel.trigger('client-change-activity', { message: this.$t('toasts.client-change-activity') }); 
        },
        loadActivity(activity) {
            this.$store.commit('setActivity', activity);
            this.$store.dispatch('updateActivity',activity);
            this.closeActivityModal(); 
        },
        triggerSavedActivity(data){
            this.loadSavedActivity(data);
            this.channel.trigger('client-load-saved-activity',  { message: this.$t('toasts.client-load-saved-activity') });
        },
        async loadSavedActivity(data) {
            if(this.$store.getters.activityCache !== null) {
                await this.$store.dispatch('saveActivity')
            }
            await this.$store.dispatch('updateActivity', data.routeName);
            await this.$store.dispatch('updateRoomLoadedSave', data.saveID);
            this.closeActivityModal(); 
        },        
        closeActivityModal() {
            this.activityModal = false;
        },
        closePlayerleft(){
            this.show.playerleft = false;
        },
        closePlayerWaiting() {
            this.show.waiting = false;
        },
        closeInactive(){
            this.show.inactive = false;
            this.show.inactiveStep2 = false;
        },        
        updateVideo(settings){
            // TODO add handling for various media input types 
            // TODO check if there's a change
            if(settings != null) {
                this.twilioVideoChat.updateVideoDevice(settings.camera);
                this.twilioVideoChat.updateAudioDevice(settings.mic);
            }
            this.closeVideoSettings();
        },
        async toggleCamera(){
            if(this.cameraStatus != 'disabled'){
                if(this.cameraStatus == 'on'){
                    this.show.localAvatar = true;
                    document.getElementById("camera_btn").firstElementChild.src = "/img/btns/video_chat/camera_off.svg";
                    document.getElementById("camera_btn").classList.add("off");
                    await this.twilioVideoChat.localCameraOff();
                    this.cameraText = this.$t('videochat.camera') + " " + this.$t('global.off');
                    this.cameraStatus = 'off';
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'camera-off'
                    });                
                } else if(this.cameraStatus == 'off'){
                    this.show.localAvatar = false;
                    document.getElementById("camera_btn").firstElementChild.src = "/img/btns/video_chat/camera_on.svg";
                    document.getElementById("camera_btn").classList.remove("off");
                    await this.twilioVideoChat.localCameraOn();
                    this.cameraText = this.$t('videochat.camera') + " " + this.$t('global.on');
                    this.cameraStatus = 'on';
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'camera-on'
                    });                          
                }
            }
        },
        async toggleMic(){  
            if(this.micStatus != 'disabled'){
                if(this.micStatus == 'on'){
                    //disconnect
                    document.getElementById("mic_btn").firstElementChild.src = "/img/btns/video_chat/mic_off.svg";
                    document.getElementById("mic_btn").classList.add("off");
                    document.getElementById("local_mic_status").src = "/img/btns/video_chat/mic_indicator_off.svg";
                    await this.twilioVideoChat.localMicOff();
                    this.micText = this.$t('videochat.mic') + " " + this.$t('global.off');  
                    this.micStatus = 'off';
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'mic-off'
                    });                       
                } else if(this.micStatus == 'off'){
                    //reconnect
                    document.getElementById("mic_btn").firstElementChild.src = "/img/btns/video_chat/mic_on.svg";
                    document.getElementById("mic_btn").classList.remove("off");
                    document.getElementById("local_mic_status").src = "/img/btns/video_chat/mic_indicator.svg";
                    await this.twilioVideoChat.localMicOn();
                    this.micText = this.$t('videochat.mic') + " " + this.$t('global.on');  
                    this.micStatus = 'on';
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'mic-on'
                    });                       
                }
            }
        },
        openVideoSettings(){
            if(this.settingsStatus != 'disabled'){
                this.show.videoSettings = true;
                this.tracking({
                    type: 'event',
                    category: 'activity:nav',
                    action: 'settings'
                });  
            }            
        },
        closeVideoSettings(){
                this.show.videoSettings = false;
        },
        leaveRoom() {
            this.confirmModal = true; 
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'leave-room',
            });             
        },         
        async exit() {  
            this.clearInactivityTime();  
            this.$emit('callEnded');
            //this.players = null;
            // Disconnects pusher
            this.$pusher.unsubscribe('presence-playspaces-' + this.$route.params.space_id);
            this.$pusher.unsubscribe('presence-playspaces-lobby-' + this.$route.params.space_id);
            this.$pusher.disconnect(); 
            // Closes Snapshot listener
            await this.unsub();
            // Closes Twilio Session
            await this.twilioVideoChat.endChat();                    
            
             if(this.activeProfile.host){
                this.sessionRole = "host";
            } else {
                this.sessionRole = "guest";
                
            }
            // Logs session activity on exit
            this.$store.dispatch('sessionLog', {
                activity: this.currentActivity  ||  "unset",
                action: "end call",
                content: null,
                players: this.players
            });  
            // Sets feedback so shown after then exit
            this.$store.commit('setFeedback', this.feedbackData); 
            this.$store.commit('setInvite', null);                      
            // Clears local storage of room data
            await this.$store.commit('setPlayers', null);
            await this.$store.commit('setActivity', null);
            this.$store.commit('setLoadedSave', null);
            if(this.activeProfile.host) {
                // Reset Room Data in Firestore
                this.$store.dispatch('updateActivity', null);
                this.$store.dispatch('updateRoomLoadedSave', null);
                // Locks room
                this.$store.dispatch('closeRoom');                
            } else {
                // reset the guest's room to their room.
                // await this.$store.dispatch('getRoom', this.activeProfile.roomId);
                await this.switchProfile(this.activeProfile);                
            }
            // Routes to homescreen view
            this.$router.push('/home');  
   
        },
        async saveExit(){
            //saves game
            let currentCache = this.$store.getters.activityCache;
          
            if(currentCache !== null) {
                await this.$store.dispatch('saveActivity')
                .then(() => {
                    this.exit(); 
                    this.confirmModal = false; 
                });   
            } else {
                await this.$store.commit('setActivityCache', null);  
                this.exit();  
            }
        },
        async justExit(){
            this.confirmModal = false;           
            //clear game cache
            await this.switchProfile(this.activeProfile);             
            await this.exit();
        },
        closeConfirmModal() {
            this.confirmModal = false;  
        },
        inviteGuest() {
            this.show.createInvite = true;
            this.$emit('closeOtherLargeModals');
            this.closeActivityModal() 
        },
        closeCreateInviteFromVideoChat() {
            this.show.createInvite = false;      
        },
        pinAccepted() {
            this.show.agegate = false;
            this.inviteGuest()
        },
        closeAgeGate() {
            this.show.agegate = false;
        },
        openAgeGate() {
            if(this.activeProfile.type === 'kid' ) { 
                console.log("open age gate");
                this.show.agegate = true;
            } else {
                this.inviteGuest()
            }
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'invite'
            });    
        },
        loadGuest(player){
            //load guest data
            if(player && this.hasMounted) {
                this.remoteProfile = player;
                this.feedbackData.playerName = this.remoteProfile.name;
                //TODO this is ugly clean it up later...
                if(document.getElementById("invite_guest")){document.getElementById("invite_guest").style.display = "none";}
                if(document.getElementById("remote_avatar")){document.getElementById("remote_avatar").style.display = "block";}
                this.show.remotename = true;
            }
        }, 
        unloadGuest(){
            if(this.hasMounted) {
                if(this.keepcallAlive === false) {
                    this.show.remotename = false;
                    //TODO this is ugly clean it up later...
                    if(document.getElementById("invite_guest")){document.getElementById("invite_guest").style.display = "block";}
                    if(document.getElementById("remote_avatar")){document.getElementById("remote_avatar").style.display = "none";} 
                    if(document.getElementById("remote_video_cam")){document.getElementById('remote_video_cam').innerHTML = "";} 
                    if(this.activeProfile.host){
                        this.show.playerleft = true; 
                    } else {
                        this.endCall();
                    }     
                    this.leavingPlayerName = this.remoteProfile.name;
                    this.leavingPlayerPhoto = this.remoteProfile.profilePhoto;
                } 
                this.remoteProfile.profilePhoto = "/img/avatars/unset_avatar.svg";
                this.remoteProfile.type = null;
                this.remoteProfile.name = null;                    
            }
        },    
        removePlayer(userProfileId) {
            let updatePlayers = [];
            this.channel.trigger('client-host-remove-player', userProfileId);
        },
        waitingSnooze() {
            this.lobby.trigger('client-host-busy', {message: 'snoozing'});
            if(this.playerWaitingDimissCount < 2) {
                this.playerWaitingDimissCount = this.playerWaitingDimissCount + 1;
                this.show.waiting = false;
                this.snoozetimeOut = 3 * 60 * 1000;
                this.resetSnooze(); 
            } else {
                // TODO update invite with status cancelled.
                this.lobby.trigger('client-host-canncelled', {message: 'cancelled'});
            }
        },
        openWaiting() {
            this.show.waiting = true;           
        },
        resetSnooze() {
            clearInterval(this.snoozetimeCount);
            this.snoozetimeCount = setInterval(() => {this.openWaiting()}, this.snoozetimeOut);
        },
        clearRoomStartCall() {
            clearInterval(this.snoozetimeCount);
            if(this.channel.members.count == 2) {
                this.removePlayer(this.remoteProfile.userProfileId);
                this.keepcallAlive = true;
            } else {
                this.startCall();
            }
            this.show.waiting = false;      
        },
        manageWaiting(waiting){
            if(waiting !== null){
                if(waiting.length > 0 && this.activeProfile.host) {
                    this.show.waiting = true;
                    this.waitingPlayerName = waiting[0].name;
                    this.waitingProfile = waiting[0];
                } else {
                    this.show.waiting = false;
                }
            } else {
                this.show.waiting = false;               
            }
        },
        async roomMonitor(){
            // Listens to the room object in firestore and loads when there's an update
            this.unsub = this.$roomsCollection.doc(this.$route.params.space_id)
            .onSnapshot( async (doc) => {
                let room = doc.data();
                room.id = doc.id;
                this.$store.commit('setCurrentRoom', room);
                //this.$store.dispatch('getActivityData');
                // Allows guest to get current game cache and load into vuex
                if(room.loadedSave !== null && this.activeProfile.host === false) {
                    // Allows guest to get current activity and load into vuex
                    await this.$store.commit('setActivity', room.currentActivity);
                    await this.$store.commit('setLoadedSave', room.loadedSave);
                } else if(this.activeProfile.host !== true) {
                    await this.$store.commit('setActivity', room.currentActivity);
                    
                }
            });  
        },
        pusherSetup(){
            if(this.activeProfile.host) {
                this.lobby = this.$pusher.subscribe('presence-playspaces-lobby-' + this.$route.params.space_id);
                
                this.lobby.bind('client-guest-waiting', (data) => {
                    //TODO launch guest waiting message.
                    let waitingRoom = [data];
                    this.manageWaiting(waitingRoom);
                }); 
                this.lobby.bind('client-guest-left', (data) => {
                    //TODO launch guest left waiting room message.
                });                 
            }
            //this.$pusher.logToConsole = true;               
            this.channel = this.$pusher.subscribe('presence-playspaces-' + this.$route.params.space_id);
       
                this.channel.bind("pusher:member_added", (member) => {
                    this.loadGuest(member.info);
                    this.loadPlayers(this.channel.members); 
                });
                this.channel.bind("pusher:member_removed", (member) => {
                    this.unloadGuest(member.info);
                    this.loadPlayers(this.channel.members);  
                })     
                this.channel.bind('client-load-saved-activity', async (data) => {
                    this.makeToast(data.message);
                    await this.$store.dispatch('getActivityData');
                });
                this.channel.bind('client-change-activity', (data) => {
                    this.makeToast(data.message);
                });                                       
                this.channel.bind('client-host-openactivity', async (data) => {
                    this.makeToast(data.message);
                    await this.$store.dispatch('getActivityData');
                });                             
                this.channel.bind('client-guest-openactivity', (data) => {
                    this.makeToast(data.message);
                }); 
                this.channel.bind('client-host-remove-player', (data) => {
                    //console.log("remove user")
                    if(!this.activeProfile.host) {
                        // TODO remove the guest.
                        this.justExit();
                    }
                });
                this.channel.bind("pusher:subscription_succeeded", (members) => {
                    this.loadPlayers(members);      
                });  
            this.channel.bind("pusher:subscription_error", (error) => {
                //this.$sentry.captureException(error);
            });                                                    
        },
        loadPlayers(members) {
            //console.log("MEMBERS COUNT: " + JSON.stringify(members.count));
            if(members.count >= 2) {
                this.feedbackData.sessionType = "2-player";
            }              
            this.players = members;
            this.$store.commit('setPlayers', members);
            //console.log("members list: " + JSON.stringify(members));
            this.players.each(player => {
                if(player.info.userProfileId !== this.activeProfile.userProfileId) {
                    this.loadGuest(player.info);
                }
            })
        },
        videoSetup(){ 
            this.pusherSetup();                     
            this.roomMonitor();
            this.localProfile = this.activeProfile;
            let localVid = document.getElementById('local_video_cam');
            let remoteVid = document.getElementById('remote_video_cam');
            this.twilioVideoChat = new twilioVideoChat(this.$store.getters.token, this.activeProfile, this.$route.params.space_id, localVid, remoteVid, this.$sentry);
            if(this.currentActivity === null) {
                this.activityModal = true;
            } else if(this.currentActivity === 'savedCreation'){
                //open activity modal and move to saved creations
                this.activityModal = true;  
                this.show.saves = true;  
            }
            if(this.activeProfile.host !== true) {
                this.waitingPlayerName = this.remoteProfile.name;
                this.startCall();
            }
            // disconnects everything if they leave    
            window.addEventListener('beforeunload', async (e) => { 
                this.clearInactivityTime();  
            });               
        },
        openPlayspace(){
            this.startCall();
            this.show.waiting = false;
        }       
    },
    mounted () {
        this.hasMounted = true;
        this.videoSetup();
    },  
    beforeDestroy() {   
        this.clearInactivityTime();  
    }       
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/_mixins.scss';

#video_chat {
    width: 100%;
    min-width: 600px;
    position: absolute;
    z-index: 9000;

    #start_video, #invite_guest {
        display: block;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        color: $ps-purple;
        line-height: 129.5%;
        width: 86px;
    }
    #start_video{
        margin: 35px auto 0;
    }
    #invite_guest {
        margin: 0 auto 0;
        background: $ps-light-purple;
        width: 110px;
        height: 110px;
        padding: 20px 0 0 0;
    }
    p {
        margin: 65px auto;
        text-align: center;
    }
    #remote_video {
        position: absolute;       
        right: 25px;
        top: 25px;
    }
    #local_video {
        position: absolute;
        left: 25px;
        top: 25px;
    } 
    .outter_crop {
        position:absolute;
        top: 0px;
        left: -20px;   
    }
    #local_mic_status {
      display: none;       
    }
    #remote_avatar {
      display: none;
    }
    .avatar-sqr{
        height: 114px;
        width: 114px;
        text-align: center;
        background: $secondary;
        img {
            margin: 20px 0 0 0;
        }
    }
    .video-shadow {
        @include box-shadow(0px,5px,10px,0, rgba(0,0,0,0.25));
        border-radius: 25px;
    }
    .video-crop {
        padding: 0;
        margin: 0;
        width: 120px;
        height: 120px;        
        overflow:hidden;
        //background-color: $ps-light-purple;
        //background: linear-gradient(0deg, #E9E9F7, #E9E9F7);
        background: $ps-light-purple url('/img/btns/loading.svg');
        background-size: contain !important;
        border: 3px solid #FFFFFF;
        border-radius: 25px;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        mask-image: radial-gradient(white, black);
        color: #302E4A;
    }
    .chat_names{
        position: relative;

        //width: 120px;
        height: 15px;
        margin: 12px 0 0 0;
        padding: 2px 0 0 0;
        color: #FFF;
        background: $ps-purple;
        border-radius: 10px;
        text-align: center;
        img.mic-indicator {
            position: absolute;
            top: 0px;
            right: 3px;
        }
        .truncate {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;  
            width: 85px;
            margin: 0 auto;      
        }
    } 
}
video {
    max-width: 240px; 
    object-fit: cover;
    right: -10px;
    position: absolute;
}
#video_controls {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    //position: absolute;
    top: 0px;
    //left: 29.4921875%; 
    max-width: 420px;
    margin: 0 auto;
    background: $ps-white;
    border-radius: 0px 0px 30px 30px;
     @include box-shadow(0px,5px,10px,0, rgba(0,0,0,0.25));
    .star-btn {
        margin-bottom: 5px;
    }
    ul {
        list-style-type: none;
        padding: 0;
        margin: 15px; 
    }
    li {
        display: inline-block;
        margin:0;
        padding:0;
        font-size: 10px;
        font-weight: 600;
        width: 60px;
        height: 48px;
        text-align: center;
        margin:0 20px 0 0;
        a {
            text-decoration: none;
            color: $ps-black;
            font-size: $nav-size;
            line-height: $nav-lh;
        }
    }
    li:last-child {
        margin:0;
    }
    .video-ctl-btn  {
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    .disabled {
        color: $ps-disabled;
        cursor: default;
    }
    .off {
        color: $ps-error;
    }
}
</style>