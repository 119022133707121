<template>
    <div>
        <Toasts
        :message="toastMsg"
        />
        <Largemodal
        :largeModalShow="activityModalShow"
        @closeLargeModal="closeActivityModal()"
        >
            <div id="activity-list" class="align-top">
                <div class="d-inline-block align-top" v-for="activity in activities" :key="activity.id">
                    <div class="lg-card align-top">
                        <img class="card-img" :src="activity.assetURL" width="360" height="260" />
                        <div class="card-btn-holder" v-if="isHost == true">
                            <b-button pill variant="primary" @click.prevent="selectActivity(activity.routeName,activity.buttonCTA);">
                                <div :class="activity.buttonIcon"></div>
                                {{activity.buttonCTA}}
                            </b-button>
                        </div>
                    </div>
                    <div class="game-description">
                        <div class="star-purple-icon d-inline-block align-top"></div> 
                        <h3 class="d-inline-block align-top">{{activity.description}}</h3>
                    </div>
                </div>
                <div class="d-inline-block align-top" >
                <div class="saved-games d-inline-block align-top" v-for="save in savedActivities" :key="save.id" >
                    <div class="lg-card">
                        <img class="card-img" src="/img/elements/play/saved_creation.svg" width="360" height="260" v-if="save.thumbPath == null"/>
                        <img class="card-img" :src="save.thumbPath" width="360" height="260" v-else/>
                        <div class="card-btn-holder">
                            <b-button pill variant="primary" @click.prevent="selectSavedActivity('Drawing',save.id);" v-if="isHost == true" >
                                <div class="edit-icon ps-icons"></div>
                                {{$t('global.edit')}}
                            </b-button>
                        </div>
                    </div>
                    <div class="game-description">
                        <div class="pencil-purple-icon d-inline-block align-top"></div> 
                        <h3 class="d-inline-block align-top" v-if="save.modifiedDisplay === null">
                            <!-- {{save.name}} -->
                            {{save.createdDisplay | moment('MMM Do, YYYY hh:mm A')}}
                        </h3>
                        <h3 class="d-inline-block align-top" v-else>
                            <!-- {{save.name}} -->
                            {{save.modifiedDisplay | moment('MMM Do, YYYY hh:mm A')}}
                        </h3>                        
                    </div>
                </div> 
                </div>                    
            </div>
         </Largemodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Largemodal from '@/components/modals/largemodal.vue'
import ActivitiesList from '@/data/en/activities.json'
import Toasts from '@/components/toast.vue'

export default {
    name: "Activitiesmodal", 
    components: {
        Largemodal,
        Toasts
    },
    props:{
        activityModalShow: Boolean,
        pusherActive: Boolean,
        focusSaves: Boolean
    },
    watch: {
        'activityModalShow': {
            handler (newVal) {
                if (this.activityModalShow === true) { 
                    this.loadSavedActivites();
                    if(this.focusSaves === true){
                        console.log("show the saves");
                        //window.scrollTo(0, 500);
                    }
                }
            },
            immediate: true 
        },
        'currentActivity': {
            handler (newVal) {
                if (newVal) { 
                    this.closeActivityModal();
                }
            },
            immediate: true 
        },
        'saves': {
            handler (newVal) {
                if (newVal) { 
                    this.savedActivities = this.$store.getters.saves;
                }
            },
            immediate: true 
        }                
    }, 
    data: function() {
        return {
            activities: ActivitiesList,
            toastMsg: null,
            savedActivities: null,
            pusher: null,
            channel: null,
            isHost: true
        }
    },
    computed: {
        ...mapState(['activeProfile']), 
        ...mapState(['currentActivity']), 
        ...mapState(['saves']),  
        channelName: function () {
            let c = 'presence-playspaces-' + this.$route.params.space_id;
            return c;
        }        
    },
    methods: {  
        async loadSavedActivites(){
            await this.$store.dispatch('getActivityData');
            this.savedActivities = this.$store.getters.saves;
        }, 
        selectActivity(activity, name){
            let trackingName = activity + ":" + name;
            this.tracking({
                type: 'event',
                category: 'playspace',
                action: trackingName.toLowerCase()
            });    
            this.$emit('changeActivity',activity);         
        },
        closeActivityModal(){
            this.$emit('closeActivityModal');            
        },
        openActivityModal(){
            this.$emit('openActivityModal');            
        },        
        selectSavedActivity(activityName, saveID){
            this.tracking({
                type: 'event',
                category: 'playspace',
                action: 'save:edit'
            });      
            let data = {
                routeName: this.activities[1].routeName,
                saveID: saveID
            };                
            if(this.activeProfile.host){
                this.$emit('loadSavedActivity', data); 
            }
        },
    },
    created() {
        this.loadSavedActivites();
    },
    mounted () {
        this.isHost = this.activeProfile.host;

    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

#activity-list {
    width: 100%;
    margin: 60px 0 0 30px;
}
.game-description {
    margin: 20px 0;
    height: 24px;
    .star-purple-icon {
        background: url('/img/btns/activity_star.svg');
        width: 20px;
        height: 20px;    
        margin-right: 5px; 
        background-repeat: no-repeat !important;
        background-position: center center;
    }
    .pencil-purple-icon {
        background: url('/img/btns/activity_pencil.svg');
        width: 20px;
        height: 20px;  
        margin-right: 5px;    
        background-repeat: no-repeat !important;
        background-position: center center;
    }    
    h3 {
        padding: 0;
    }
}

</style>