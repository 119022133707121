<template>
    <div id="inactive-modal">
        <Smallmodal
        :modalShow="show.modalOn"
        @closeSmallModal="clickClose()"
        >
        <div id="inactive-modal-content">
            <div v-if="show.step1 == true">
                <img src="/img/elements/play/waiting.svg" width="280" height="250" :alt="$t('inactive.stillplaying')" :title="$t('inactive.stillplaying')"/>
                <br/><br/>
                <h2>{{$t('inactive.stillplaying')}}</h2>
                <br/><br/>
                <b-button pill variant="primary" @click="keepPlaying()">
                    <div class="play-icon ps-icons d-inline-block"></div>
                    {{$t('inactive.keepplaying')}}
                </b-button>
            </div>
            <div v-if="show.step2 == true">
                <img src="/img/elements/play/inactivity.svg" width="253" height="226" :alt="$t('inactive.disconnected')" :title="$t('inactive.disconnected')"/>
                <br/><br/>
                <h2>{{$t('inactive.disconnected')}}</h2>
                <br/><br/>
                <b-button pill variant="primary" @click="okay()">
                    <div class="confirm-icon ps-icons d-inline-block"></div>
                    {{$t('global.okay')}}
                </b-button>
            </div>            
        </div>
        </Smallmodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'
export default {
    name: "Inactivenudge", 
    components: {
        Smallmodal
    },
    props:{
        showInactive: Boolean,
        inactiveStep2: Boolean,
    },
    watch: {
        'showInactive': {
            handler (newVal) {
                if (newVal && this.showInactive == true) { 
                    this.show.modalOn = true;
                    this.show.step1 = true;
                    this.show.step2 = false;
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'popup:view',
                        label: 'inactivetimeout'
                    });
                    this.waitAminute();
                } else {
                    if(this.show.step2 == true && this.inactiveStep2 == true){
                        this.show.modalOn = true; 
                    } else {
                       this.show.modalOn = false; 
                    }                    
                    clearInterval(this.minCount);
                }
            },
            immediate: true 
        },
        'inActivateTimeOut': {
            handler (newVal) {
                if (newVal === true) { 
                    // do something?
                }
            },
            immediate: true 
        }         
    },       
    computed: {
        ...mapState(['userData']), 
        ...mapState(['inActivateTimeOut']),
    },
    data: function() {
        return {
            error: null,
            minCount: null,
            show: {
                modalOn: false,
                step1: true,
                step2: false
            }
        }   
    },
    methods: {
        clickClose(){
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:close',
                label: 'inactivetimeout'
            }); 
            this.closeModal();
        },
        closeModal() {
            this.$emit('closeInactive');
            this.$store.commit('setInactive', false);          
        },
        keepPlaying(){
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:keepplaying',
                label: 'inactivetimeout'
            });
            this.closeModal();
        },
        okay() {
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:okay',
                label: 'inactivetimeout'
            });
            this.closeModal();
            clearInterval(this.minCount);
        },
        waitAminute() {
            let timeOut = 1 * 60 * 1000;
            clearInterval(this.minCount);
            this.minCount = setInterval(() => {this.endCall()}, timeOut);        
        },
        endCall(){
            this.$emit('disconnectSession');
            this.show.step1 = false;
            this.show.step2 = true;
            // reset timeer
            this.$store.commit('setInactive', false); 
            clearInterval(this.minCount);
            this.minCount = null;
        }
    },
    mounted(){

    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';



</style>