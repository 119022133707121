<template>
  <div id="play-space-container">
    <div id="play-space-flex">
        <Videochat 
        :closeLargeModals="closeLargeModals"
        @callStarted="callStarted()"
        @callEnded="callEnded()"
        @modalsClosed="modalsClosed()"
        @closeOtherLargeModals="closeGameLargeModals()"
        />
        <div id="play_area">
          <game :is="activity()" 
          :closeGameModals="closeGameModals"
          @resetClose="resetClose()" 
          @closeLargeModals="closeModals()">
          </game>
        </div>
    </div>    
  </div>
</template>

<script>
// This layout is the layout for playspaces and games.
import Vue from 'vue';
import { mapState } from 'vuex'
import Videochat from '@/components/videochat.vue'
import psPusher from '@/services/pusher';

export default {
  name: "playlayout",  
  components: {
    Videochat,
  }, 
  computed: {
    ...mapState(['activeProfile']), 
    ...mapState(['currentActivity']),  
    ...mapState(['players']), 
    ...mapState(['currentRoom']),
    Drawing() {
      return () => import(/* webpackChunkName: "Drawing" */ '@/pages/play/drawing/index.vue');
    }, 
    SimpleMachines() {
      return () => import(/* webpackChunkName: "simple-machines" */ '@/pages/play/simple-machines/SimpleMachines.vue');
    },
    activity() {
      //Dynamically loads activity without page refresh.
      let activity = null;
      if(this.currentActivity != null){
        if(this.currentActivity == 'SimpleMachines'){
          activity = this.SimpleMachines;
        } else if(this.currentActivity == 'Drawing'){
          activity = this.Drawing;
        } else {
          activity = null;
        } 
      } 
      return () => activity
    }
  },
  data() {
    return {
      closeLargeModals: false,
      closeGameModals: false,
    }
  },
  methods: {
    callStarted(){
      
    },
    callEnded(){
      
    },    
    closeModals(){
      this.closeLargeModals = true;
    },
    modalsClosed(){
      this.closeLargeModals = false;      
    },
    closeGameLargeModals(){
      this.closeGameModals = true;           
    },
    resetClose(){
      this.closeGameModals = false;       
    },
    pusherConnect(){
    
    }  
  }, 
  beforeCreate() {
      Vue.prototype.$pusher = new psPusher();
      this.$pusher.connection.bind("connected", function () {
        console.log("PUSHER CONNECTED")
      });
      this.$pusher.connection.bind("disconnected", function () {
        console.log("PUSHER Disconnected")
      });
  },
  beforeDestroy() {
    //this.drawingApp.destroy(); 
  },  
  mounted() {

    if(this.currentActivity === null) {
    // only fire this tag if there's no activity set.
      this.tracking({
        type: 'page',
        title: '/playspace',
      }); 
      
    }
  }, 
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
html, #App {
  overflow: hidden !important;
}
#play-space-flex {
    -webkit-user-select: none;
    -moz-user-select: -moz-none; 
    -ms-user-select: none; 
    user-select: none;     
    width: 1024px;
    height: 100%;
    max-height: 768px;
    min-height: 640px;
    position: absolute;
    z-index: 300;
    background: url('/img/backgrounds/play/activity_start.svg');
}
#play-space-container {
  position: relative;
  margin:0 auto;
  width: 1024px;
  height: 100%;
}
#play_area {
    position: absolute;  
    bottom: 0px;
    left: 0px;
    width: 1024px;
    height: 768px;  
}


</style>