<template>
  <div id="general-modal-container" v-show="modalShow">
    <div class="general-modal">
      <div id="exit-step-1" v-if="exitStep1 == true">
        <img src="/img/elements/play/goodby_alien.svg" width="233" height="154"/>
        <h2>{{$t('videochat.readytoleave.heading')}}</h2>
        <p>{{$t('videochat.readytoleave.message')}}</p>
        <br/>
        <b-row>
          <b-col>
            <div class="circle-btn-cancel" @click="cancelModalAction()"><img src="/img/btns/cancel.svg" width="50" height="50" alt="Cancel Button" title="Cancel Button"/></div>
          </b-col>
          <b-col>
            <div class="circle-btn-done" @click="confirmedLeaving()" v-if="loading == false">
              <img src="/img/btns/checkmark.svg" width="50" height="50" alt="Confrim Button" title="Confrim Button"/>
            </div>
            <div class="circle-btn-done" v-else>
                <img src="/img/btns/loading_white.svg" width="50" height="50" :alt="$t('global.loading')" :title="$t('global.loading')"/>
            </div>                 
          </b-col>
        </b-row>
      </div>
      <div id="exit-step-2" v-if="exitStep2 == true">
        <img src="/img/elements/play/save_work_satellite.svg" width="233" height="154"/>
        <h2>{{$t('videochat.savework.heading')}}</h2>
        <p>{{$t('videochat.savework.message')}}</p>
        <div v-if="error" class="error-msg">{{error}}</div>
        <br/>
        <b-row>
          <b-col>
            <div class="circle-btn-cancel" @click="leaveWithoutSaving()"><img src="/img/btns/cancel.svg" width="50" height="50" alt="Cancel Button" title="Cancel Button"/></div>
          </b-col>
          <b-col>
            <div class="circle-btn-done" @click="confirmSaveExit()"><img src="/img/btns/checkmark.svg" width="50" height="50" alt="Confrim Button" title="Confrim Button"/></div>
          </b-col>
        </b-row>        
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        exitStep1: true,
        exitStep2: false,
        loading: false
      }
    },
    computed: {
      ...mapState(['activeProfile']), 
    },
    watch: {
        'modalShow': {
            handler (newVal) {
                if (newVal && this.modalShow == true) { 
                  this.tracking({
                      type: 'event',
                      category: 'activity:nav',
                      action: 'goodbye',
                  }); 
                  this.loading = false;
                }
            },
            immediate: true 
        }
    }, 
    props: {
      modalShow: Boolean,
      error: String
    },    
    methods: {
      showStep1(){
        this.exitStep1 = true;
        this.exitStep2 = false;
      },
      showStep2(){
        this.exitStep1 = false;
        this.exitStep2 = true;
      },   
      cancelModalAction(){
        this.tracking({
            type: 'event',
            category: 'activity:nav',
            action: 'goodbye:no'
        });          
        //No in step 1
        this.$emit('cancelModalAction');
        //this.showStep1();
      },         
      confirmedLeaving() {
        // ! step 2 was disabled.
        this.tracking({
            type: 'event',
            category: 'activity:nav',
            action: 'goodbye:yes'
        });          
        //Yes in step 1
        this.loading = true;
        if(this.activeProfile.host){
          this.confirmSaveExit();
        } else {
          this.leaveWithoutSaving();
        }        

      },
      leaveWithoutSaving() {
        /*this.tracking({
            type: 'event',
            category: 'activity:nav',
            action: 'savework:no'
        });*/        
        //No in step 2
        this.$emit('leaveWithoutSaving');
        //this.showStep1();
      },
      confirmSaveExit() {
        /*  this.tracking({
            type: 'event',
            category: 'activity:nav',
            action: 'savework:yes'
        });   */      
        //Yes in step 2
        this.$emit('confirmSaveExit');
        //this.showStep1();
      }
    },
    mounted(){

    }
  }
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#general-modal-container {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 8000;
  width: 100%;
  height: 100%;
  background: rgba(38, 32, 114, 0.5);
}
.circle-btn-cancel, .circle-btn-done {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 auto;
  text-align: center;
  img {
    text-align: center;
    margin: 15px auto 0 auto;
  } 
}
.circle-btn-cancel {
  background: #FFF;
  border: 1px solid $ps-purple;
  
}
.circle-btn-done {
  background: $ps-purple;
}
.general-modal {
  position: relative;
  top: 50%;
  transform: translateY(-50%);      
  padding: 25px 60px;
  background: $ps-light-purple;
  border-radius: 30px; 
  max-width: 390px;
  height: 390px;
  text-align: center;
  margin: 0 auto; 
}
</style>