<template>
    <div id="inactive-modal">
        <Smallmodal
        :modalShow="showModal"
        @closeSmallModal="closeModal()"
        >
        <!-- Player Waiting UNUSED-->
        <div v-if="show.playerwaiting == true">
            <div>
                <img src="/img/elements/play/next_playdate.svg" width="280" height="250"/>
            </div> 
            <br/><br/>
            <h2>{{playerName}}{{$t('videochat.waiting.heading')}}</h2>
            <br/><br/>
            <b-button pill variant="primary" @click="startNow()" >
                <div class="play-icon ps-icons"></div>
                {{$t('videochat.startnow')}}
            </b-button>        
        </div> 
        <!-- Call Waiting-->
        <div v-if="show.callwaiting == true">
            <div>
                <img src="/img/elements/play/next_playdate.svg" width="280" height="250"/>
            </div> 
            <br/><br/>
            <h2>{{playerName}}{{$t('videochat.waiting.heading')}}</h2>
            <br/><br/>
            <b-button pill variant="primary" class="btn-short" @click="snooze()" >
                <div class="alarm-icon ps-icons"></div>
                {{$t('videochat.waiting.snooze')}}
            </b-button>                 
            <b-button pill variant="primary" class="btn-right btn-short" @click="startNowEndGuest()" >
                <div class="play-icon ps-icons"></div>
                {{$t('videochat.startnow')}}
            </b-button>        
        </div>
        <!-- Call Waiting - Ending Current Playdate-->
        <div v-if="show.endcurrent == true">
            <div>
                <img src="/img/elements/play/next_playdate.svg" width="280" height="250"/>
            </div> 
            <br/><br/>
            <h2>{{$t('videochat.waiting.currentwillend')}}</h2>
            <br/><br/>           
            <b-button pill variant="primary" @click="letsGo()" >
                <div class="play-icon ps-icons"></div>
                {{$t('global.letsgo')}}
            </b-button>        
        </div> 
        <!-- Call Waiting - Cancel Playdate-->
        <div v-if="show.cancel == true">
            <div>
                <img src="/img/elements/play/next_playdate.svg" width="280" height="250"/>
            </div> 
            <br/><br/>
            <h2>Are you sure you want to cancel your playdate with {{playerName}}?</h2>
            <br/><br/>
            <b-button pill variant="primary" class="btn-short" @click="goBack()" >
                <div class="alarm-icon ps-icons"></div>
                Go back
            </b-button>                          
            <b-button pill variant="primary" @click="cancelPlaydate()" >
                <div class="play-icon ps-icons"></div>
                Cancel
            </b-button>        
        </div>         
        </Smallmodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'

export default {
    name: "playerwaiting", 
    components: {
        Smallmodal
    },
    props:{
        showModal: Boolean,
        playerName: String
    },
    watch: {
        'showModal': {
            handler (newVal) {
                if (newVal && this.showModal == true) { 
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'popup:view',
                        label: 'playerwaiting'
                    });
                    this.show.callwaiting = false;
                    this.show.callwaiting = true;
                    this.show.endcurrent = false;                    
                }
            },
            immediate: true 
        }       
    },      
    computed: {
        ...mapState(['userData']),
        ...mapState(['players']),
        ...mapState(['currentRoom']),          
    },
    data: function() {
        return {
            error: null,
            show: {
                playerwaiting: false,
                callwaiting: true,
                endcurrent: false,
                cancel: false
            }            
        }   
    },
    methods: {
        loadWaiting() {
            // Not being used for now everyone gets call waiting screen.
            if(this.players) {
                //console.log("players here:" + this.players.length)
                if(this.players.length >= 2){
                    this.show.playerwaiting = false;           
                    this.show.callwaiting = true;
                    this.show.endcurrent = false;
                } 
            } 
        },
        closeModal() {
            this.$emit('snoozeStart');
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:close',
                label: 'playerwaiting'
            });            
        },
        startNow() {
            this.$emit('startVideo');  
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:startnow',
                label: 'playerwaiting'
            });              
        },
        snooze() {
            this.$emit('snoozeStart');
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:snooze',
                label: 'playerwaiting'
            });              
        },
        startNowEndGuest() {
            if(this.players) {
                //console.log("players here:" + this.players.length)
                if(this.players.length >= 2){
                    this.show.playerwaiting = false;           
                    this.show.callwaiting = false;
                    this.show.endcurrent = true;  
                } else {
                    this.$emit('endCurrentStartNew');
                }
            } else {
                // TODO this should probably result in a bigger error?
                this.$emit('endCurrentStartNew');                
            }            
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:startnow',
                label: 'playerwaiting'
            });                   
        },
        letsGo() {
            this.$emit('endCurrentStartNew');
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:letsgo',
                label: 'playerwaiting'
            });               
        },
        goBack() {

        },
        cancelPlaydate() {
            // TODO add cancel playdate
        }
    },
    mounted(){

    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
.btn-right {
    margin-left: 10px;
}
</style>