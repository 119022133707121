<template>
    <div id="large-modal-container" v-if="largeModalShow == true" v-on:click.self="closeModal()">
        <div id="large-modal">
            <div id="activities-aliens">
                <img src="/img/elements/play/activities_alien_friends.svg" width="150" height="90"/>
            </div>
            <div id="modal-close" @click.prevent="closeModal()">
                <img src="/img/btns/close.svg" width="22" height="22"/>
            </div>  
            <div id="large-drawer-slide">
                <div id="large-drawer">
                    <slot/>
                </div>
            </div>
            <div>
                <div id="large-message-holder">
                    <div id="large-message" v-if="isHost == true">
                        <h3>{{$t('activites.yourehost')}}</h3>
                    </div>
                    <div id="large-message" v-else>
                        <h3>{{$t('activites.hostwillchose')}}</h3>
                    </div>                    
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: "Largemodalwithscrolling", 
    props:{
        largeModalShow: Boolean,
        layerHeight: Number
    },
    computed: {
    ...mapState(['activeProfile']),
    },   
    data: function() {
    return {
            largemodal: {
                slider: null,
                isDown: false,
                startX: null,
                scrollLeft: null  
            },
            settings: {
                suppressScrollY: true,
                suppressScrollX: false,
                wheelPropagation: false
            },
            isHost: false
        }
    },
    watch: {
        'largeModalShow': {
            handler (newVal) {
                if (newVal) { 
                    //this.setup(); 
                }
            },
            immediate: true 
        }
    },    
    methods: {
        closeModal(){
            this.$emit('closeLargeModal');
        },        
        preventBehavior(e) {
            e.preventDefault(); 
        },
        initModal() {
            //document.ontouchmove = function(e){ return true; }
            this.isHost = this.activeProfile.host;              
        }        
    },
    mounted () {
        this.initModal();         
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
@import '@/assets/scss/_mixins.scss';
.scroll-area {
    position: relative;
    margin: auto;
    width: 860px;
    height: 460px;
}
#modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 7100;
  padding: 9px 0;
}
#large-modal-container {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 6500;
    width: 100%;
    height: 100%;
    background: rgba(38, 32, 114, 0.5);
}
#activities-aliens{
    position: absolute;
    top: -58px;
    right: 50px;   
}
#large-modal{
    position: relative;
    top: 52%;
    transform: translateY(-50%);    
    width: 860px;
    height: 460px;
    border-radius: 30px;
    background: $ps-light-purple;
    @include box-shadow(0px,5px,10px,0, rgba(0,0,0,0.25));
    margin: 0 auto;
    
    ::-webkit-scrollbar {
        width: 10px;
    }    
    div::-webkit-scrollbar-track {
        backwground: #E9E9F6 !important; 
        box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.1);  
    }
    /* Handle */
    div::-webkit-scrollbar-thumb {
        background: #B4B0E8 !important;
        border-radius: 15px;
        //filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));   
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);     
    }
    /* Handle on hover */
    div::-webkit-scrollbar-thumb:hover {
        background: $ps-purple;
    }    
    img { 
        -webkit-user-drag: none; 
        -khtml-user-drag: none; 
        -moz-user-drag: none; 
        -o-user-drag: none; 
        user-drag: none; 
    }
    #large-drawer-slide{
        overflow: hidden;
        box-sizing: content-box;
        white-space: nowrap;
        height: 422px;
        width: 860px;
        margin: 0;
        padding: 0;   
    }
    #large-drawer{
        height: 422px;
        width: 100%;
        box-sizing: content-box;
        white-space: nowrap;
        -webkit-overflow-scrolling: touch;
        overflow: scroll;
        overflow-y: hidden;
        margin: 0;
        padding: 0;   
    }
    #large-message-holder{
        background: $ps-white;
        width: 100%;
        height: 37px;   
        border-radius: 0 0 30px 30px;
        overflow: hidden;    
    }
    #large-message {
        padding: 8px 0 0 0;
        font-weight: 500;
        text-align: center;
        color: $ps-purple;        
    }
}

</style>