<template>
    <div id="playerleft-modal">
        <Smallmodal
        :modalShow="showModal"
        @closeSmallModal="closeModal()"
        >
        <div id="playerleft-modal-content">
            <!-- Player Left-->
            <div v-if="show.playerleft == true">
                <img src="/img/elements/play/goodby_alien.svg" width="233" height="154"/>
                <br/><br/>
                <h2>{{playerName}}{{$t('videochat.leftroom')}}</h2>
                <br/><br/>
                <b-button pill variant="primary" @click="endCall()" >
                    <div class="confirm-icon ps-icons"></div>
                    {{$t('global.okay')}}
                </b-button>  
            </div>                                                           
        </div>
        </Smallmodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'

export default {
    name: "playerleft", 
    components: {
        Smallmodal
    },
    props:{
        showModal: Boolean,
        playerName: String,
    },
    watch: {
        'showModal': {
            handler (newVal) {
                if (newVal && this.showModal == true) { 
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'popup:view',
                        label: 'playerleft'
                    });
                }
            },
            immediate: true 
        },
        'currentRoom': {
            handler (newVal) {
                if (newVal && this.showModal == true) { 
                    //do something
                }
            },
            immediate: true 
        }        
    },      
    computed: {
        ...mapState(['userData']), 
        ...mapState(['activeProfile']),
        ...mapState(['currentRoom']), 
    },
    data: function() {
        return {
            error: null,
            show: {
                playerleft: true,
                playerwaiting: false,
                callwaiting: false,
                endcurrent: false,
            }
        }   
    },
    methods: {
        playsolo() {
            this.$emit('closePlayerleft');            
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:playsolo',
                label: 'playerleft'
            }); 
        },
        closeModal() {
            this.$emit('closePlayerleft');
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:close',
                label: 'playerleft'
            });            
        },
        endCall(){
            this.$emit('disconnectSession');            
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:okay',
                label: 'playerleft'
            });              
        }
    },
    mounted(){

    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#playerleft-modal-content {
    padding: 30px 0;
    .avatar-container{
        text-align: center;
        margin: 0 auto;
    }
}


</style>